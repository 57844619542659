/* eslint no-console:0 */ // This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("@rails/activestorage").start();require("channels");import Rails from'@rails/ujs';Rails.start();import $ from'jquery';import Sortable from"sortablejs";$(document).ready(function(){var el=document.getElementById("skills")||document.getElementById("subjects");if(!el){return;}var sortable=Sortable.create(el,{handle:'.handle',onSort:function onSort(evt){var url=el.dataset.sortUrl;fetch(url,{method:"POST",headers:{"Content-Type":"application/json","X-CSRF-Token":$('meta[name=csrf-token]').attr('content')},body:JSON.stringify({order:sortable.toArray()})});}});// $('#skills, #subjects').sortable({
// axis: 'y',
// handle: '.handle',
// update: function() {
// $.ajax({
// type: 'POST',
// url: $(this).data('sort-url'),
// data: { order: $(this).sortable('toArray') },
// })
// },
// });
});